
//-- general
html, body { width: 100%; height: 100%; } 
body {
  margin: 0;
  padding: 0;
}

.main-section {
  margin: auto 20px;
  display: flex;
  flex-direction: column;
  // 100vh; means the height of this element is equal to 100% of the viewport height.
  min-height: 80vh;
  flex-grow: 1;
 justify-content: center;
  //padding: 20px 0;
}

header {
  border-bottom: 5px solid #263040;
  margin-bottom: 20px;
  background-color: #ffffff;

  &.form-header {
    border-bottom: 0;
    margin-bottom: 0;
    padding-left: 0;
  }

  .logo {
    padding: 20px;
  }
}

footer {
  margin-top: 20px;
  padding: 20px 0;

  .copyright {
    padding: 0 0 0 20px;
  }

  .footerLinks {
    padding: 0 20px 0 0;
  }
}

.form-container {
  margin: 0 auto;
}

.login-fields-wrapper {
  display: flex;
  align-items: center;

  #remember-cb {
    align-items: flex-start;
    > label {
      padding: 6px 0;
    }
  }

  .link-container {
    align-items: flex-end;
    text-align: right;
    width: 50%;
  }
}

// vertical align placeholder text in IE
.custom-field {
  vertical-align: middle;
}

.question-text {
  margin: 30px 0 0;
  padding: 0;
}

.default-labels {
  padding-top: 20px;
}

.default-values {
  color: #aaaaaa;
}

.submit-btn.update {
  margin-right: 2px;
}

::placeholder {
  font-style: italic;
}

.bold-font-500 {
  font-weight: 500;
}

.select-placeholder {
  font-weight: 400;

}

.text-align-center {
  text-align: center;
}

[class*='DropButton'] .select-placeholder {
  padding: 5px 11px;
  color: #aaaaaa;
}
[class*='DropButton'] input[value=""]::placeholder {
  color: #aaaaaa;
}

@media only screen and (max-width: 568px) {
  form.create-account {
    .create-container {
      flex-direction: column;
    }
  }
}

.profile-page-form-edit-footer {
  display: flex;
  gap: 20px;
}